import { PDFDocument, PageSizes } from 'pdf-lib'
import { SharedAttachmentService } from '@/services'
export default {

  methods: {

    async pdfMake (
      options = { header: [], content: [], footer: [], styles: {}, images: {}, watermark: null },
      pageOptions = { pageSize: 'A4', pageMargins: [40, 90, 40, 75], pageOrientation: 'portrait', fontSize: 10 },
      info = { title: '', author: '', creator: '', subject: '' }
    ) {
      return this.$pdfmake.createPdf({
        pageOrientation: pageOptions.pageOrientation, // landscape, portrait
        pageSize: pageOptions.pageSize,
        pageMargins: pageOptions.pageMargins,
        info: {
          title: info.title,
          author: info.author,
          creator: info.creator,
          subject: info.subject
        },
        defaultStyle: {
          font: 'Arial',
          fontSize: pageOptions.fontSize,
          alignment: 'justify',
          characterSpacing: 1,
          lineHeight: 1,
          bold: true
        },
        header: options.header,
        content: options.content,
        footer: options.footer,
        images: {
          invalidImage: `${window.location.origin}/img/invalid-image.png`,
          aljay: `${window.location.origin}/img/invalid-image.png`,
          ...options.images
          // strawberries: {
          //   url: 'http://localhost:8000/api/shared/files/branding/aljay-solutions-small.jpg',
          //   headers: {
          //     Authorization: `Bearer ${this.$store.getters['auth/token']}`
          //   }
          // }
        },
        styles: options.styles,
        watermark: options.watermark
      }, null, {
        Arial: {
          normal: `${window.location.origin}/assets/arial/regular.ttf`,
          bold: `${window.location.origin}/assets/arial/bold.ttf`,
          italics: `${window.location.origin}/assets/arial/italic.ttf`,
          bolditalics: `${window.location.origin}/assets/arial/bold-italic.ttf`
        }
      })
    },

    async pdfLib (
      info = { title: '', author: '', creator: '', subject: '' }
    ) {
      return await PDFDocument.create().then(
        async pdfDocument => {
          pdfDocument.setTitle(info.title, { showInWindowTitleBar: true })
          // pdfDocument.setAuthor(info?.author || ' ')
          // pdfDocument.setCreator(info?.creator || ' ')
          // pdfDocument.setSubject(info?.subject || ' ')
          return pdfDocument
        }
      )
    },

    async pdfLibCopyPages (pdfDocument, pdfBytes, pagesToCopyIndices = [], callback = null) {
      return await PDFDocument.load(pdfBytes, { ignoreEncryption: true }).then(async pagesToCopy => {
        const indices = pagesToCopyIndices.length > 0 ? pagesToCopyIndices : pagesToCopy.getPageIndices()
        await pdfDocument.copyPages(pagesToCopy, indices).then(selectedPagesToCopy => {
          selectedPagesToCopy.forEach((page, index) => {
            if (typeof callback === 'function') {
              callback(page, index, pdfDocument, indices)
            } else {
              page.setSize(PageSizes.A4[0], PageSizes.A4[1])
              pdfDocument.addPage(page)
              pdfDocument.save()
            }
          })
        })
      })
    },

    async calculateEmbededImageSize (pdfDocument, embededImage) {
      const pageHeight = pdfDocument.getHeight()
      const pageWidth = pdfDocument.getWidth()

      const ratio = Math.min((pageWidth / 3) / embededImage.width, (pageHeight / 3) / embededImage.height)

      return {
        width: embededImage.width * ratio,
        height: embededImage.height * ratio
      }
    },

    async getPDFBase64Url (arrayBuffer) {
      return new Promise(resolve => {
        window.pdfjsLib.getDocument(arrayBuffer).promise.then(
          async pdfDocument => {
            const page = await pdfDocument.getPage(1)
            const pageViewport = await page.getViewport({ scale: 1 })
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.width = pageViewport.width
            canvas.height = pageViewport.height
            page.render({
              canvasContext: context,
              viewport: pageViewport
            }).promise.then(() => {
              resolve(canvas.toDataURL('image/png'))
            })
          }
        )
      })
    },

    async convertPageToImage (pdf, iPage, fileName) {
      const page = await pdf.getPage(iPage)
      const viewport = page.getViewport({ scale: 1 })
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = viewport.width
      canvas.height = viewport.height
      await page.render({
        canvasContext: context,
        viewport: viewport
      }).promise

      return {
        file_original_name: fileName,
        data_uri: canvas.toDataURL('image/png')
      }
    },

    async getPages (pdf) {
      const pages = []
      for (let iPage = 1; Number(pdf.numPages) >= iPage; iPage++) {
        const page = await pdf.getPage(iPage)
        const viewport = page.getViewport({ scale: 1 })
        pages.push({ page, viewport })
      }

      return pages
    },

    async pdfLibCopyAttachmentsTwo (paymentRequest) {
      return await new Promise(resolve => {
        const attachments = paymentRequest.attachments.filter(
          attachment => attachment.file_extension === 'pdf'
        )

        if (attachments.length <= 0) {
          resolve([])
          return []
        }

        // const invalidAttachments = []
        const bufferedData = []

        return attachments.map(
          async (attachment, index) => {
            await SharedAttachmentService.retrieveAttachments(
              paymentRequest.attachment_path,
              attachment.file_hashed_name
            ).then(
              ({ data }) => data.arrayBuffer()
            ).then(
              async imageBytes => {
                if (Number(imageBytes.byteLength) > 0) {
                  window.pdfjsLib.getDocument(imageBytes).promise.then(
                    async pdf => {
                      const BATCH_SIZE = 2

                      const numPages = Number(pdf.numPages)
                      let currentPage = 1

                      while (currentPage <= numPages) {
                        const batchEnd = Math.min(currentPage + BATCH_SIZE - 1, numPages)
                        const promises = []
                        for (let iPage = currentPage; iPage <= batchEnd; iPage++) {
                          promises.push(this.convertPageToImage(pdf, iPage, attachment.file_original_name))
                        }
                        const batchImages = await Promise.all(promises)
                        bufferedData.push(...batchImages)
                        currentPage += BATCH_SIZE
                      }
                    }
                  )
                } else {
                  bufferedData.push({
                    file_original_name: `${attachment.file_original_name} - Invalid Attachment`,
                    data_uri: null
                  })
                }

                if ((index + 1) === (attachments.length)) {
                  resolve(bufferedData)
                }
              }
            )
          }
        )
      })
    }

    // default: {
    //   const urlEncoded = window.btoa(
    //     new Uint8Array(imageBytes).reduce(
    //       (data, byte) => data + String.fromCharCode(byte), ''
    //     )
    //   )
    //   bufferedData.push({
    //     file_original_name: attachment.file_original_name,
    //     data_uri: `data:image/${attachment.file_extension};base64,${urlEncoded}`
    //   })
    //   break
    // }

    // async pdfLibCopyAttachments(pdfDocument, paymentRequest) {
    //   return await new Promise(resolve => {
    //     const imageOptions = {
    //       x: Number(0),
    //       y: Number(0),
    //       width: Number(0),
    //       height: Number(0),
    //       rotate: degrees(0
    //     }

    //     if (paymentRequest.attachments.length <= 0) {
    //       resolve(pdfDocument)
    //       return pdfDocument
    //     }

    //     const invalidAttachments = []

    // this.pdfLibCopyPages(
    //   pdfDocument,
    //   imageBytes,
    //   [],
    //   async copyOfPage => {
    //
    //     // await PDFDocument.create().then(
    //     //   async pdf => {
    //     //     const pager = pdf.addPage()
    //     //     pager.setSize(
    //     //       PageSizes.A4[0],
    //     //       PageSizes.A4[1]
    //     //     )
    //     //     await pdf.embedPage(copyOfPage).then(
    //     //       embededPdf => {
    //     //         pager.drawPage(embededPdf)
    //     //         pdf.save().then(
    //     //           async pdfBytes => {
    //     //             this.getPDFBase64Url(pdfBytes).then(
    //     //               arrayBuffer => {
    //     //                 bufferedData.push(arrayBuffer)
    //     //               }
    //     //             )
    //     //           }
    //     //         )
    //     //       }
    //     //     )
    //     //   }
    //     // )
    //   }
    // )
    //     paymentRequest.attachments.filter((x, i) => i >= 1).forEach(
    //       async (attachment, index) => {
    //         await SharedAttachmentService.retrieveAttachments(
    //           paymentRequest.attachment_path,
    //           attachment.file_hashed_name
    //         )
    //           .then(({ data }) => data.arrayBuffer())
    //           .then(
    //             async imageBytes => {
    //               if (Number(imageBytes.byteLength) > 0) {
    //                 switch (attachment.file_extension) {
    //                   case 'pdf': {
    //                     await this.pdfLibCopyPages(pdfDocument, imageBytes)
    //                     break
    //                   }
    //                   case 'png': {
    //                     const page = pdfDocument.addPage()
    //                     const embededImage = await pdfDocument.embedPng(imageBytes)
    //                     const imageDimentions = await this.calculateEmbededImageSize(page, embededImage)
    //                     imageOptions.width = parseInt(imageDimentions.width)
    //                     imageOptions.height = parseInt(imageDimentions.height)
    //                     if (imageOptions.width > imageOptions.height) {
    //                       imageOptions.x = parseInt((page.getWidth() / 2) + (imageDimentions.height / 2))
    //                       imageOptions.y = parseInt((page.getHeight() / 2) - (imageDimentions.width / 2)) + 10
    //                       imageOptions.rotate = degrees(90)
    //                       page.drawText(attachment.file_original_name, { x: (imageOptions.x - imageDimentions.height), y: parseInt(imageOptions.y - 10), size: 10 })
    //                     } else {
    //                       imageOptions.x = parseInt((page.getWidth() / 2) - (imageDimentions.width / 2))
    //                       imageOptions.y = parseInt((page.getHeight() / 2) - (imageDimentions.height / 2)) + 10
    //                       imageOptions.rotate = degrees(0)
    //                       page.drawText(attachment.file_original_name, { x: imageOptions.x, y: parseInt(imageOptions.y - 10), size: 10 })
    //                     }
    //                     page.drawImage(embededImage, imageOptions)
    //                     pdfDocument.save()
    //                     break
    //                   }
    //                   default: {
    //                     const page = pdfDocument.addPage()
    //                     const embededImage = await pdfDocument.embedJpg(imageBytes)
    //                     const imageDimentions = await this.calculateEmbededImageSize(page, embededImage)
    //                     imageOptions.width = parseInt(imageDimentions.width)
    //                     imageOptions.height = parseInt(imageDimentions.height)
    //                     if (imageOptions.width > imageOptions.height) {
    //                       imageOptions.x = parseInt((page.getWidth() / 2) + (imageDimentions.height / 2))
    //                       imageOptions.y = parseInt((page.getHeight() / 2) - (imageDimentions.width / 2)) + 10
    //                       imageOptions.rotate = degrees(90)
    //                       page.drawText(attachment.file_original_name, { x: (imageOptions.x - imageDimentions.height), y: parseInt(imageOptions.y - 10), size: 10 })
    //                     } else {
    //                       imageOptions.x = parseInt((page.getWidth() / 2) - (imageDimentions.width / 2))
    //                       imageOptions.y = parseInt((page.getHeight() / 2) - (imageDimentions.height / 2)) + 10
    //                       imageOptions.rotate = degrees(0)
    //                       page.drawText(attachment.file_original_name, { x: imageOptions.x, y: parseInt(imageOptions.y - 10), size: 10 })
    //                     }
    //                     page.drawImage(embededImage, imageOptions)
    //                     pdfDocument.save()
    //                   }
    //                 }
    //               } else {
    //                 invalidAttachments.push(attachment)
    //               }
    //               if ((index + 1) === 7) {
    //                 if (invalidAttachments.length > 0) {
    //                   const page = pdfDocument.addPage()
    //                   invalidAttachments.forEach((invalid, key) => {
    //                     page.drawText(
    //                       ` ${key + 1}). Invalid File Format for ${invalid.file_extension.toUpperCase()} Type: ${invalid.file_original_name}`, {
    //                       x: 50,
    //                       y: (page.getHeight() / 1.05) - (key * 15),
    //                       size: 10
    //                     }
    //                     )
    //                     if (invalidAttachments.length === (key + 1)) {
    //                       resolve(pdfDocument)
    //                     }
    //                   })
    //                 } else {
    //                   resolve(pdfDocument)
    //                 }
    //               }
    //             }
    //           )
    //       }
    //     )
    //   })
    // },

  }
}
